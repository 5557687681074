.contact-btn {
  width: 100%;
  height: var(--display-lg-size);
  padding: 14px 20px;
  margin-top: 35px;
  margin-bottom: 70px;
}
.contact-btn .simple-button {
   margin: 0 auto;
}

.contact-reg-office-address {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 36px;
}

.contact-reg-office-address span {
  
    font-family: var(--global-font-family) !important;
    color: rgba(0, 35, 102, 1);
    font-weight: var(--font-w-500);

}

.contact-reg-office-address span:first-child {
  font-weight: var(--font-w-700);
}
.swal2-styled.swal2-confirm {
  background-color: var(--Primary-Mid-Blue) !important;
  color: var(--twotixx-white) !important;
  font-family: var(--global-font-family) !important;
  border-radius: 1 !important;
}

.custom-label.MuiInputLabel-root {
  font-family: var(--global-font-family);
  font-size: var(--body-md-size);
  font-weight: var(--font-w-400);
  line-height: var(--padding-xl);
  letter-spacing: var(--padding-0);
  text-align: left;
}

.contact-main .footer-d {
  position: inherit;
}

.contact-body-sec {
  text-align: left;
  /* padding-left: 3%; */
  padding: 64px 3%;
  padding-top: 35px;
  font-family: var(--global-font-family) !important;
  /* padding-bottom: 50px; */
}

@media (max-width: 767px) {
  .contact-body-sec {
    text-align: left;
    padding-left: 3%;
    font-family: var(--global-font-family) !important;
  }
}
.contact-inner-block {
  display: flex;
  flex-wrap: wrap;
  max-width:1186px;
  margin:0 auto;
 /* align-items: flex-end; */
}
.contact-body-sec h2 {
  font-size: 56px;
  font-weight: var(--font-w-600);
  color: rgba(0, 87, 255, 1);
  margin: 0 0 30px 0;
}

.contact-body-lft {
  width: 50%;
  padding: 0 6% 0 0;
}
.contact-body-rht {
  /* background: url(/public/help-bg.svg) no-repeat right 80px top 150px/auto calc(100% - 150px);
  background-attachment: fixed;
  
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: -200px;
  align-self: flex-end;
  margin-left: 80px; */
  position:sticky;
  top:125px;
  height:100%;
  width: calc(55% - 80px);
}

.contact-rhtImg {
  /* width: 100%;
  height: 100%;
  opacity: 0; */
  max-width:100%;
}

@media (max-width: 767px) {
  .contact-body-rht {
    width: 60%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: -50px;
  }

  .contact-body-lft{
    padding:0;
  }
  .contact-rhtImg {
    max-width: 100%;
    width: 100%;
  }
}

.lft-description {
  padding-bottom: var(--padding-xl);
}

.lft-desc-text {
  font-family: var(--global-font-family) !important;
  color: rgba(0, 35, 102, 1);
  font-weight: var(--font-w-500);

}

.lft-form {
  /* margin-top: -80px; */
}
@media (max-width: 767px) {
  .lft-form {
    margin-top: -80px;
    padding-left: 10px;
    padding-right: 25px;
    /* padding: 15px; */
  }
}

.contact-body-lft .MuiGrid-root span {
  color: rgba(0, 35, 102, 1);
  font-size: var(--body-md-size);
  font-weight: var(--font-w-500);
  display: block;
  margin: 0 0 3px;
}



.contact-body-lft .MuiGrid-item {
  padding: 22px 0 0;
}

@media (max-width: 767px) {
  .contact-btn button.simple-button {
    width: 100% !important;
  }
  .contact-btn {
    padding: 0;
  }
  .contact-body-sec h2 {
    font-size: 40px;
    font-weight: 600;
    padding-left: 8px;
  }
  .contact-body-lft,
  .contact-body-rht {
    width: var(--full-width);
  }
  .contact-body-rht {
    background-image: none;
    padding: 50px 0 0;
    margin-left: 0;
  }
  .contact-rhtImg {
    margin: var( --padding-0s);
    opacity: 1;
  }
  .lft-form {
    margin-top: -55px;
  }
}
